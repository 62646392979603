    /*==========================================================================
   Includes
   ========================================================================== */

    //Open Sans all fonts
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic');
    //Roboto all weights but no italic
    @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
    @import "bootstrap/variables";
    /* ==========================================================================
       General Variables
    ========================================================================== */

    $logo-image: 'images/logo.png';
    $background-image: 'images/pattern.jpg';
    /* Colors
      ========================================================================== */

    $light-yellow :#FED235;
    $dark-orange: #ff7e00;
    $dark-blue: #284156;
    $purple: #FF40E4;
    $citrex-white: #EBEFF3;
    $citrex-gray:#8992A4;
    $citrex-grey:#8992A4;
    $citrex-primary: $dark-blue;
    $citrex-secondary: #ffb400;

    /* Typography
       ========================================================================== */

    body {
        font-family: "Roboto", Arial, sans-serif;
    }
    /* Basic Sizes
       ========================================================================== */

    $bar-height: '40px';
    $margin-from-top: '118px';
    html,
    body {
        padding: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    /* Mixins
       ========================================================================== */

    @mixin shadow ($size:5px,
    $transparency:0.4,
    $color:black) {
        box-shadow: 0 0 $size 0 rgba($color, $transparency);
    }

    @mixin noselect() {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                      supported by Chrome and Opera */
    }

    @mixin nooutline() {
        &:focus:active {
            outline: none;
            box-shadow: none;
        }
        &:focus {
            outline: none;
        }
    }

    @mixin placeholder ($color:black) {
        *::-webkit-input-placeholder {
            /* Safari, Chrome and Opera */
            color: $color !important;
        }
        *:-moz-placeholder {
            /* Firefox 18- */
            color: $color !important;
        }
        *::-moz-placeholder {
            /* Firefox 19+ */
            color: $color !important;
        }
        *:-ms-input-placeholder {
            /* IE 10+ */
            color: $color !important;
        }
        *:-ms-input-placeholder {
            /* Edge */
            color: $color !important;
        }
        *:placeholder-shown {
            /* Standard one last! */
            color: $color !important;
        }
    }


    /* General Styles
       ========================================================================== */

    .noselect {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                      supported by Chrome and Opera */
    }

    .no-padding {
        padding: 0;
    }

    .no-padding-inner {
        padding: 0;
        &:first-child {
            padding-left: 15px;
        }
        &:last-child {
            padding-right: 15px;
        }
    }

    .no-padding-left {
        padding-left: 0;
    }

    .no-padding-right {
        padding-right: 0;
    }

    .posr {
        position: relative;
    }

    .posf {
        position: fixed;
    }

    input.toggle {
        max-height: 0;
        max-width: 0;
        opacity: 0;
    }

    input.toggle+label {
        display: block;
        position: relative;
        box-shadow: inset 0 0 0 1px #d5d5d5;
        text-indent: -5000px;
        height: 20px;
        width: 40px;
        border-radius: 15px;
        margin-left: 25%;
    }

    input.toggle+label:before {
        content: "";
        position: absolute;
        display: block;
        height: 20px;
        width: 20px;
        top: 0;
        left: 0;
        border-radius: 15px;
        background: rgba(19, 191, 17, 0);
        -moz-transition: .25s ease-in-out;
        -webkit-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }

    input.toggle+label:after {
        content: "";
        position: absolute;
        display: block;
        height: 20px;
        width: 20px;
        top: 0;
        left: 0px;
        border-radius: 15px;
        background: white;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .2), 0 2px 4px rgba(0, 0, 0, .2);
        -moz-transition: .25s ease-in-out;
        -webkit-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }

    input.toggle:checked+label:before {
        width: 40px;
        background: rgba(19, 191, 17, 1);
    }

    input.toggle:checked+label:after {
        left: 20px;
        box-shadow: inset 0 0 0 1px rgba(19, 191, 17, 1), 0 2px 4px rgba(0, 0, 0, .2);
    }

    label {
        @include noselect();
        margin-bottom: 0;
    }

    .dropdown-menu>li>a:hover,
    .dropdown-menu>li>a:focus {
        background-color: transparent;
        color: $citrex-secondary;
    }

    .citrex {
        input[type=text],
        input[type=url],
        input[type=password],
        input[type=email],
        input[type=number],
        input[type=tel],
        input[type=datetime-local],
        input[type=datetime]{
            background-color: white;
            border: none;
            border-radius: 6px;
            display: inline-block;
            color: #8992A5;
            font-weight: 300;
            padding: 10px;
            //height: auto;
            //max-height: 52px;
            //margin-bottom: 40px;
            &:focus {
                outline: none;
            }
        }
        input[type=radio],
        input[type=checkbox] {
            &:focus {
                outline: none;
            }
        }

        select {
            background-color: white;
            border: none;
            border-radius: 6px;
            display: inline-block;
            color: #8992A5;
            font-weight: 300;
            &:focus {
                outline: none;
            }
        }
        .c-checkbox {
            position: relative;
            width: 15px;
            height: 15px;
            margin-top: 0;
            vertical-align: text-top;
            margin-right: 15px;
            // visibility: hidden;
            &:before,
            &:after {
                width: 100%;
                height: 100%;
            }
            &:before {
                content: "";
                border-radius: 3px;
                display: block;
                background-color: white;
            }
            &:checked:after {
                content: "";
                background-image: url("../images/check.svg");
                background-repeat: no-repeat;
                background-size: 80%;
                background-position: center;
                position: absolute;
                top: 0;
                left: 0;
                text-align: center;
            }
        }
    }

    select.form-control {
        //background-image: url('../images/down-arrow.svg');
        background-repeat: no-repeat;
        background-size: 13px;
        background-position: 95% 50%;
    }

    .form-group {
        position: relative;
        margin-bottom: 20px;
        .c-checkbox,
        .c-radio,
        .c-select {
            border: none;
            width: 100%;
            margin-bottom: 0;
        }
        select+svg {
            display: block;
            position: absolute;
            //  right: 15px;
            //  top: 15px;
        }
        svg,
        button.absolute {
            position: absolute;
            top: 11px;
            right: 30px;
        }
        button.absolute {
            background: none;
            border: none;
            svg {
                position: relative;
                right: auto;
                top: auto;
            }
        }
    }

    .filters {
        padding: 0;
        // padding-left: 35px;
        // width: 100%;
        // max-width: 300px;
        @media (max-width: $screen-xs-max){
            form{
                padding: 15px;
                padding-right: 30px;
            }
            .form-group{
                margin-bottom: 15px;
            }
        }
        form {
            margin-top: 15px;
        }
        .form-group {
            position: relative;
            margin-bottom: 10px;
            input,
            select {
                border: none;
                width: 100%;
                margin-bottom: 0;
            }
            select+svg {
                display: block;
                position: absolute;
                //  right: 15px;
                //  top: 15px;
            }
            svg,
            button.absolute {
                position: absolute;
                top: 11px;
                right: 30px;
            }
            button.absolute {
                background: none;
                border: none;
                svg {
                    position: relative;
                    right: auto;
                    top: auto;
                }
            }
            label {
                color: $citrex-white;
                font-weight: 300;
                text-align: left;
                font-size: 16px;
            }
        }
        .form-group.checkbox-group,
        .form-group.radio-group {
            div {
                overflow: hidden;
            }
            label {
                background-color: $citrex-white;
                width: 50%;
                padding: 15px;
                box-sizing: border-box;
                margin: 0;
                float: left;
                color: $citrex-gray;
                transition: background-color 0.7s;
                &.active {
                    background-color: #FFC100;
                    span {
                        color: $citrex-primary;
                    }
                }
                &:first-child {
                    -webkit-border-radius: 6px 0 0 6px;
                    border-radius: 6px 0 0 6px;
                }
                &:last-child {
                    -webkit-border-radius: 0 6px 6px 0;
                    border-radius: 0 6px 6px 0;
                }
                input {
                    display: inline-block;
                    text-align: left;
                    width: auto;
                }
            }
        }
    }

    .page {
        width: 100%;
        height: 100%;
        background-color: $citrex-primary;
        box-shadow: 0 0 10px 0 rgba(black, 0.5);
        position: absolute;
        left: 0;
        z-index: 5;
        transition: all 0.4s;
        overflow-x:hidden ;
        @media (max-width:$screen-xs-max) {
            overflow: auto;
        }
        @media (max-width:$screen-md-min) and (max-width:$screen-sm-max){
            overflow: hidden;
        }
        &.active {
            left: $screen-xs-min;
            position: fixed;
        }
        header {
            overflow: hidden;
            position: relative;
            z-index: 1;
            background-repeat: no-repeat;
            // background-size: 100%;
            background-position: 100% 0;
            background-color: darken($citrex-primary, 5%);
            padding: 15px;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: .4;
                z-index: -1;
                background-image: url('../images/bg-pattern1.png');
            }
            button {
                box-shadow: none;
                outline: none;
                display: inline-block;
                border: none;
                //float: left;
                margin-left: 7px;
                transition: all 0.4s;
                &:focus,
                &:hover {
                    outline: none;
                    @include shadow(6px,
                    0.6,
                    black);
                }
                &:active:focus {
                    @include shadow(1px,
                    0.6,
                    black);
                    outline: none;
                    border: none;
                }
                img {
                    width: 30px;
                    height: 20px;
                }
            }
            .menu-icon {
                box-sizing: content-box;
                width: 30px;
                height: 20px;
                position: relative;
                /* margin: 5px; */
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: .5s ease-in-out;
                -moz-transition: .5s ease-in-out;
                -o-transition: .5s ease-in-out;
                transition: .5s ease-in-out;
                cursor: pointer;
                display: block;
                float: left;
                // position: absolute;
                // left: 0;
                // top:0;
                background-color: transparent;
                span {
                    display: block;
                    position: absolute;
                    height: 4px;
                    width: 30px;
                    background: white;
                    border-radius: 3px;
                    opacity: 1;
                    // left: 5px;
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    transform: rotate(0deg);
                    -webkit-transition: .25s ease-in-out;
                    -moz-transition: .25s ease-in-out;
                    -o-transition: .25s ease-in-out;
                    transition: .25s ease-in-out;
                }
                span:nth-child(1) {
                    top: 0;
                }
                span:nth-child(2),
                span:nth-child(3) {
                    top: 8px;
                }
                span:nth-child(4) {
                    top: 16px;
                }
                &.open span:nth-child(1) {
                    top: -2px;
                    width: 0%;
                    left: 50%;
                }
                &.open span:nth-child(2) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                &.open span:nth-child(3) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
                &.open span:nth-child(4) {
                    top: -2px;
                    width: 0%;
                    left: 50%;
                }
            }
            @media (max-width:$screen-xs-max) {
                .context{
                    float: left !important;
                    margin-top: 15px;
                    button{
                        margin-left: 0;
                        margin-right: 15px;
                    }
                }
            }
            h2 {
                font-weight: 300;
                font-size: 30px;
                color: #EBEFF3;
                margin: 0;
                margin-left: 20px;
                float: left;
                line-height: 22px;
            }
        }
        .subheader {
            background-color: $citrex-secondary;
            padding: 5px;
            &:before {
                background: none;
            }
            .menu-btn {
                margin-left: 15px;
                margin-top: 6px;
            }
            .form-group {
                position: relative;
                margin-bottom: 0;
                width: 77%;
                max-width: 300px;
                float: left;
                input {
                    width: 100%;
                    margin-bottom: 0;
                    padding: 6px 12px;
                }
                button.absolute {
                    position: absolute;
                    top: 7px;
                    right: 10px;
                }
                button.absolute {
                    background: none;
                    border: none;
                    svg {
                        position: relative;
                        right: auto;
                        top: auto;
                    }
                }
            }
        }
    }

    .glare {
        overflow: hidden;
        &:after {
            background: white;
            content: "";
            height: 300%;
            left: -150%;
            top: -45%;
            opacity: 0.2;
            position: absolute;
            -webkit-transform: rotate(35deg);
            -ms-transform: rotate(35deg);
            transform: rotate(15deg);
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            width: 150px;
            z-index: 1;
        }
        &:hover:after,
        &.active {
            left: 130%;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }

    .svg {
        &-primary {
            fill: $citrex-primary;
            //margin-bottom: 5px;
        }
        &-secondary {
            fill: $citrex-secondary;
        }
        &-black {
            fill: black;
        }
        &-white {
            fill: white;
        }
        &-gray {
            fill: $citrex-gray;
        }
    }

    .citrex table {
        select {
            margin-bottom: 0;
        }
    }

    .file_drop_zone {
        border: 2px dashed $citrex-gray;
        min-height: 100px;
        margin-top: 15px;
        text-align: center;
        padding: 35px;
    }

    button.no-btn,
    .btn.no-btn {
        background: none;
        border: none;
        box-shadow: none;
        outline: none;
        &:hover,
        &:active,
        &:focus {
            background: none;
            box-shadow: none;
        }
        .menu-icon{
            margin-top: 0;
            margin-left: 0;
        }
    }

    .btn-default:active,
    .btn-default.active,
    .open>.btn-default.dropdown-toggle {
        background: none;
    }

    .btn {
        font-family: "Roboto", Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        @include nooutline();
        span+svg {
            margin-left: 5px;
            vertical-align: middle;
        }
        &:hover {
            background-color: darken($citrex-white, 10%);
        }
        &-default {
            background-color: $citrex-white;
            color: $citrex-primary;
            &.btn-danger {
                color: darken($brand-danger, 5%);
                svg {
                    fill: darken($brand-danger, 5%);
                }
                &:hover,
                &:focus {
                    background-color: darken($citrex-white, 5%);
                }
                &:active:focus {
                    color: darken($brand-danger, 5%);
                }
            }
        }
        &-success {
            color: $citrex-white;
            &:hover {
                background-color: darken($brand-success, 5%);
            }
        }
        &-secondary {
            background-color: $citrex-secondary;
            color: #fff;
            fill: #fff;
            svg {
                fill: #fff;
            }
        }
    }

    .pill.more {
        border-radius: 4px;
        background-color: #fff;
        color: $citrex-primary;
        border: none;
        font-weight: bold;
        font-size: 1.4em;
        width: 30px;
        height: 14px;
        @include noselect();
        overflow: hidden;
        vertical-align: top;
        margin-left: 15px;
        .svg-primary{
            margin-bottom: 10px;
        }
    }

    ul.citrex-tabs.nav-tabs {
        margin-top: 0 !important;
        padding-top: 15px;
        background-color: lighten($citrex-primary, 20%);
        border-bottom: none;
        li {
            margin-bottom: 0;
            a {
                background-color: transparent;
                border: none;
                border-bottom: 3px solid transparent;
                color: darken(white, 20%);
                &:hover {
                    color: #fff;
                }
            }
            &.active a {
                background-color: transparent;
                border: none;
                border-bottom: 3px solid white;
            }
        }
    }

    ul.nav-tabs {
        margin-top: 15px !important;
        li {
            a {
                background-color: #C9D3DF;
                color: #8393A7;
                font-weight: 300;
                margin-right: 0;
                transition: all 0.4s;
                &.active {
                    background-color: #62B7EE;
                    color: #fff;
                    border: 1px solid #62B7EE;
                    border-bottom-color: transparent;
                }
            }
            &.active a {
                background-color: #62B7EE;
                color: #fff;
                border: 1px solid #62B7EE;
                border-bottom-color: transparent;
            }
        }
    }

    .tab-content {
        .tab-pane {
            color: $citrex-gray;
        }
    }

    .pagination-section {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        background: #ADB9C9;
        padding: 5px;
    }

    .pagination {
        margin: 0;
        line-height: 1em;
        li {
            display: inline-block;
            margin-left: 5px;
            height: 20px;
            a {
                transition: all 0.4s;
                color: black;
                border-radius: 4px !important;
                padding: 4px;
                line-height: 10px;
            }
            &:hover a {
                background-color: lighten(#62B7EE, 5%);
                border-color: lighten(#62B7EE, 5%);
                color: #fff;
            }
            &.active a {
                background-color: #62B7EE;
                border-color: #62B7EE;
                color: #fff;
            }
        }
    }

    .performance-bar {
        width: 200px;
        .bar {
            width: 100%;
            border: 3px solid;
            border-radius: 6px;
            height: 35px;
            position: relative;
            .percentage {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                span {
                    position: absolute;
                    right: 5px;
                    line-height: 30px;
                    font-weight: bold;
                }
            }
        }
        .info {
            padding-top: 5px;
            p {
                margin: 0;
            }
        }
        &.success .bar {
            border-color: $brand-success;
            .percentage {
                background-color: $brand-success;
            }
        }
        &.warning .bar {
            border-color: $brand-warning;
            .percentage {
                background-color: $brand-warning;
            }
        }
        &.danger .bar {
            border-color: $brand-danger;
            .percentage {
                background-color: $brand-danger;
            }
        }
    }
    /* ==========================================================================
   Header / Navigation
   ========================================================================== */

    nav.main {
        width: 100%;
        max-width: $screen-xs-min;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1;
        overflow: auto;
        ul.navigation {
            margin: 0;
            padding: 0;
            height: auto;
            &>li {
                padding: 30px;
                list-style: none;
                height: 130px;
                text-align: center;
                position: relative;
                overflow: hidden;
                transition: all 0.4s;
                &:after {
                    background: white;
                    content: "";
                    height: 371px;
                    left: 120%;
                    top: -80px;
                    opacity: 0.2;
                    position: absolute;
                    -webkit-transform: rotate(35deg);
                    -ms-transform: rotate(35deg);
                    transform: rotate(35deg);
                    -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
                    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
                    width: 150px;
                    z-index: 1;
                }
                &:hover:after,
                &.active {
                    left: -210px;
                    -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
                    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
                }
                &.citrex {
                    .wrapper {
                        span {
                            color: #53657D;
                            font-size: 12px;
                            font-family: "Roboto", Arial, sans-serif;
                        }
                        img {
                            width: 65px;
                            height: auto;
                            display: block;
                            margin: 0 auto;
                        }
                    }
                    &:hover .wrapper img {
                        margin-top: 0;
                    }
                }
                &.home {
                    .wrapper {
                        img {
                            width: 65px;
                            height: auto;
                        }
                        .label {
                            margin-top: 10px;
                            color: rgba(white, 0.6);
                        }
                    }
                    &:hover {
                        .wrapper img {
                            margin-top: 0;
                        }
                        .wrapper .label {
                            margin-top: 10px;
                        }
                    }
                }
                &.lessons {
                    .wrapper .label {
                        color: rgba(white, 0.6);
                    }
                }
                &>a{
                    display: block;
                    position: relative;
                    margin: 0 auto;
                    height: 100%;
                    font-size: 12px;
                    img {
                        transition: all 0.3s;
                        display: block;
                        margin: 0 auto;
                        width: 25px;
                        height: auto;
                        clear: both;
                        margin-top: 0;
                    }
                    &.toggle-submenu {
                        position: absolute;
                        bottom: 10px;
                        height: 10px;
                        left: 47%;
                        img {
                            width: 13px;
                            height: auto;
                        }
                    }
                    strong {
                        font-weight: 800;
                    }
                    strong,
                    span {
                        color: rgba(black, 0.4);
                        font-family: "Open Sans", Arial, sans-serif;
                    }
                    .label {
                        transition: all 0.3s;
                        display: block;
                        margin-top: 30px;
                        font-size: 100%;
                        padding: 0;
                    }
                }
                &>a.toggle-submenu{

                }
                ul.sub-menu{
                    display: none;
                    margin-left: 0;
                    padding-left: 0;
                    li{
                        list-style-type: none;
                        border-bottom: 1px solid lighten(orange,5%);
                        display: block;
                        height: auto;
                        a{
                            display: inline-block;
                            color:#fff;
                            font-weight: lighter;
                            padding: 10px 100px 10px 100px;
                        }
                    }
                }
                &.expanded{
                    height: auto;
                    &:after{
                        opacity:0;
                    }
                    & a .label,
                    &:hover a .label{
                        margin-top: 10px;
                    }
                }
                &.expanded ul.sub-menu{
                    display: block;
                }
                &:hover .wrapper img {
                    margin-top: 10px;
                }
                &:hover .wrapper .label {
                    margin-top: 10px;
                }
            }
            li.blue {
                background-color: #0084DC;
            }
            li.pink {
                background-color: $citrex-secondary;
            }
            li.green {
                background-color: #80B63A;
            }
            li.orange {
                background-color: #FC7800;
            }
            li.yellow{
                background-color: $light-yellow;
            }
        }
    }

    .column {
        overflow-y: auto;
    }
    @media (max-width: $screen-xs-max ){
        .col-sm-12.column{
            padding: 0;
            .col-sm-4{
                padding: 0;
            }
        }
    }

    .popup {
        display: none;
        position: absolute;
        right: 0;
        border-radius: 10px;
        background-color: #fff;
        min-width: 270px;
        overflow: hidden;
        @include shadow(8px,
                0.3,
                black);
        z-index: 15;
        &.active {
            display: block;
        }
        h4 {
            @include noselect();
            padding: 10px;
            background-color: $citrex-gray;
            //  color:$citrex-white;
            color: black;
            font-weight: 100;
            font-size: 24px;
            margin-top: 0;
        }
        .btn-close {
            position: absolute;
            right: 0;
            top: 10px;
            background: none;
            border: none;
            color: $citrex-white;
            &:focus:active {
                @include shadow(0,
                        0,
                        transparent);
                outline: none;
            }
            &:focus {
                outline: none;
                @include shadow(0,
                        0,
                        transparent);
            }
        }
        ol {
            max-height: 200px;
            overflow: auto;
            padding-left: 15px;
            li {
                list-style-type: decimal;
                font-size: 16px;
                color: black;
                overflow: auto;
                max-height: 300px;
                list-style-position: inside;
            }
        }
    }

    .column>div,
    .column>ul {
        height: auto;
        width: 100%;
        & .lesson {
            width: 100%;
            min-height: 100px;
            border-bottom: 1px solid $citrex-gray;
            aside {
                // max-width: 30px;
                width: auto;
                height: 100%;
                text-align: center;
                padding: 0 10px;
                display: inline-block;
                vertical-align: top;

                &>li {
                    text-align: right;
                    position: relative;
                    height: 20px;
                    clear: both;
                    margin-top: 10px;
                    list-style: none;
                    .c-checkbox {
                        position: relative;
                    }
                }
                li.level span {
                    text-align: center;
                    display: inline-block;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    line-height: 17px;
                    background-color: $citrex-white;
                    color: $citrex-primary;
                    font-weight: 400;
                    cursor: default;
                }
                li.exposure .fa{
                    color: #fff;
                    width:16px;
                    height:16px;
                    margin-right: 3px;
                }
                input {
                    margin: 0;
                }
            }
            @media (max-width: $screen-xs-max ) {
                aside{
                    padding: 0;
                }
            }

            main {
                display: inline-block;
                color: white;
                width: 89%;
                h4 {
                    font-weight: 600;
                    font-size: 24px;
                }
                li>.btn {
                    margin-top: 15px;
                }
                li.meta {
                    strong {
                        margin-right: 5px;
                    }
                    span {
                        color: $citrex-gray;
                    }
                    .popup {
                        display: none;
                        position: absolute;
                        right: 0;
                        border-radius: 10px;
                        background-color: #fff;
                        min-width: 200px;
                        overflow: hidden;
                        @include shadow(8px,
                        0.3,
                        black);
                        z-index: 15;
                        &.active {
                            display: block;
                        }
                        h4 {
                            @include noselect();
                            padding: 10px;
                            background-color: $citrex-gray;
                            //  color:$citrex-white;
                            color: black;
                            font-weight: 100;
                            font-size: 24px;
                            margin-top: 0;
                        }
                        .btn-close {
                            position: absolute;
                            right: 0;
                            top: 10px;
                            background: none;
                            border: none;
                            color: $citrex-white;
                            &:focus:active {
                                @include shadow(0,
                                0,
                                transparent);
                                outline: none;
                            }
                            &:focus {
                                outline: none;
                                @include shadow(0,
                                0,
                                transparent);
                            }
                        }
                        ol {
                            max-height: 200px;
                            overflow: auto;
                            padding-left: 15px;
                            li {
                                list-style-type: decimal;
                                font-size: 16px;
                                color: black;
                                overflow: auto;
                                max-height: 300px;
                                list-style-position: inside;
                            }
                        }
                    }
                }
                .profile-image picture {
                    margin-top: 15px;
                    display: block;
                    border-radius: 50%;
                    width: auto;
                    height: auto;
                    max-height: 125px;
                    max-width: 125px;
                    position: relative;
                    //overflow: hidden;
                    .private-icon{
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 10;
                        width: 20px;
                        height: auto;
                    }
                }
                @media (max-width: $screen-xs-min ) {
                    .profile-image picture{
                        max-width:none;
                        max-height:none;
                        .private-icon{
                            width: 30px;
                            height: auto;
                        }
                        img{
                            margin-bottom: 15px;
                        }
                    }
                }
            }
            @media (max-width:$screen-md-max){
                main{
                    width:84%;
                }
            }
        }
        @media(max-width:$screen-sm-max) and (min-width:$screen-sm-min){
            & .lesson{
                height:220px;
            }
        }
    }

/////////

    @media (max-width: 700px) {
        .transformer-tabs {
            ul {
                border-bottom: 0;
                overflow: hidden;
                position: relative;
                background: #666;
            &::after {
                content: "☰";
                position: absolute;
                top: 8px;
                right: 15px;
                z-index: 2;
                pointer-events: none;
            }
                &.open {
                    li{
                        display: block;
                        width: 100%;
                    }
                }
            }
            li {
                display: none;
            }
            li.active{
                display: block;
                background-color: #fff;
                border-bottom: none;
                a{
                    color: #000 !important;
                }
            }

        }
    }
    /* ==========================================================================
   Login Page
   ========================================================================== */

    body.citrex.login {
        width: 100%;
        height: 100%;
        padding: 0;
        background-color: #C9D3DF;
        background-image: url("../images/login-bg.jpg");
        background-size:cover ;
        //background-size: 10%;
        .page {
            background-color: transparent;
            box-shadow: none;
        }
    }

    body.citrex.login .page {
        position: relative;
        overflow: auto;
        h1 {
            text-align: center;
            img{
                width: 130px;
                height: auto;
            }
        }
        h2 {
            color: $dark-blue;
            // text-transform: uppercase;
            font-size: 40px;
            text-align: center;
            font-weight: 300
        }
        h3 {
            color: $dark-blue;
            // text-transform: uppercase;
        }
        form {
            transition: all 0.4s;
            position: absolute;
            text-align: center;
            left: 50%;
            // top: 50%;
            margin-left: -225px;
            width: 450px;
            height: auto;
            min-height: 400px;
            background-color: $citrex-primary;
            border-radius: 6%;
            @include shadow (6px,
            0.5);
            padding: 50px 86px;
            .form-group {
                overflow: hidden;
            }
            .form-group.password input {
                margin-bottom: 0;
            }
            .remember-group {
                margin: 0 auto;
                clear: both;
                margin-top: 30px;
                margin-bottom: 15px;
                label {
                    color: #C9D3DF;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 25px;
                }
            }
            a.forgot {
                color: #C2D5E1;
                font-size: 14px;
                margin-top: 5px;
                font-weight: 300;
            }
            button#log_in {
                color:#284156;
                background-color: $citrex-secondary;
                border: none;
                width: 156px;
                height: 45px;
                margin-bottom: 15px;
            }
            label{
                color:#946902;
            }
        }
        @media (max-width:$screen-xs-min){
            form{
                width: 100%;
                left: 0;
                margin-left: 0;
                padding: 15px;
                padding-top: 30px;
                border-radius:10px;
                .form-group{
                    //margin-bottom: 0;
                }
            }
        }
    }
    /* ==========================================================================
   Teacher Home
   ========================================================================== */

    body.citrex.teacher {
        main {
            ul.nav.dashboard-items {
                padding-top: 30px;
                width: 100%;
                max-width:550px;
                height: 580px;
                margin: 0 auto;
                li {
                    background-color: black;
                    display: block;
                    float: left;
                    width: 33.3%;
                    height: 200px;
                    //margin-bottom: 30px;
                    &:nth-child(even) {
                        //margin-left: 30px;
                    }
                    a {
                        transition: all 0.4s;
                        display: block;
                        text-align: center;
                        padding: 0;
                        font-family: "Open Sans", Arial, sans-serif;
                        font-weight: bold;
                        font-size: 20px;
                        color: white;
                        &:hover {
                            background-color: transparent;
                        }
                    }
                    &:hover a {
                        // font-size: 26px;
                        color: yellow;
                    }
                }
                .flex-vertical-center{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                li.classes {
                    background-image: url("../images/apple-books.png");
                }
                .round-top-left{
                    border-radius: 10% 0 0 0;
                }
                .round-top-right{
                    border-radius: 0 10% 0 0;
                }
                .round-bottom-left{
                    border-radius: 0 0 0 10%;
                }
                .round-bottom-right{
                    border-radius: 0 0 10% 0;
                }

                .round-right{
                    border-radius: 0 10% 10% 0;
                }
                .round-left{
                    border-radius: 10% 0 0 10%;
                }
                .round{
                    border-radius: 10% 10% 10% 10%;
                }
                li.tasks {
                    background-image: url("../images/chess.png");

                    background-position: 20%;
                }
                li.content {
                    background-image: url("../images/movies.png");

                }
                li.performance {
                    background-image: url("../images/cubes.png");
                    background-position: 70%;
                }
                li.recommended-lessons {
                    background-image: url("../images/teachers-recommended-bg.png");
                    background-position: 50% 60%;
                }li.calendar {
                    background-image: url("../images/calendar.jpg");
                    background-position: 50% 60%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                li.online-lessons {
                    background-image: url("../images/online-image.jpg");
                    background-position: 50% 60%;
                }
            }
        }
    }
    /* ==========================================================================
   Tasks
   ========================================================================== */

    body.citrex.tasks {
        .videos {
            position: relative;
            header {
                background-color: $citrex-secondary;
                margin-top: 20px;
                padding-bottom: 5px;
                border-right: 1px solid lighten($citrex-secondary, 5%);
                &>div {
                    height: 33px;
                }
                h3 {
                    font-size: 25px;
                    font-weight: 300;
                    color: $citrex-white;
                    margin: 0;
                    margin-bottom: 30px;
                }
                label {
                    color: white;
                    font-weight: 300;
                    font-family: 'Roboto', Arial, Helvetica, sans-serif;
                    margin-top: 12px;
                    input {
                        margin-right: 10px;
                    }
                }
            }
            .column {
                overflow-y: auto;
                overflow-x: hidden;
                padding-bottom: 50px;
                ul {
                    margin: 0;
                    padding: 0;
                    // text-align: right;
                }
                &>ul {
                    height: auto;
                    width: 100%;
                    &>li.lesson {
                        list-style: none;
                        width: 100%;
                        min-height: 200px;
                        border-bottom: 1px solid $citrex-gray;
                        aside {
                            // max-width: 30px;
                            width: auto;
                            height: 100%;
                            text-align: center;
                            padding: 0 4px;
                            display: inline-block;
                            vertical-align: top;
                            &>ul>li {
                                list-style: none;
                                text-align: right;
                                position: relative;
                                height: 20px;
                                clear: both;
                                margin-top: 10px;
                                .c-checkbox {
                                    position: relative;
                                }
                            }
                            li.level span {
                                text-align: center;
                                display: inline-block;
                                border-radius: 50%;
                                width: 16px;
                                height: 16px;
                                line-height: 17px;
                                background-color: $citrex-white;
                                color: $citrex-primary;
                                font-weight: 400;
                                cursor: default;
                            }
                            input {
                                margin: 0;
                            }
                        }
                        main {
                            display: inline-block;
                            color: white;
                            width: 90%;
                            h4 {
                                font-weight: 600;
                                font-size: 24px;
                            }
                            li>.btn {
                                margin-top: 15px;
                            }
                            li.meta {
                                strong {
                                    margin-right: 5px;
                                }
                                span {
                                    color: $citrex-gray;
                                }
                                .popup {
                                    display: none;
                                    position: absolute;
                                    right: 0;
                                    border-radius: 10px;
                                    background-color: #fff;
                                    min-width: 200px;
                                    overflow: hidden;
                                    @include shadow(8px,
                                    0.3,
                                    black);
                                    z-index: 15;
                                    &.active {
                                        display: block;
                                    }
                                    h4 {
                                        @include noselect();
                                        padding: 10px;
                                        background-color: $citrex-gray;
                                        //  color:$citrex-white;
                                        color: black;
                                        font-weight: 100;
                                        font-size: 24px;
                                        margin-top: 0;
                                    }
                                    .btn-close {
                                        position: absolute;
                                        right: 0;
                                        top: 10px;
                                        background: none;
                                        border: none;
                                        color: $citrex-white;
                                        &:focus:active {
                                            @include shadow(0,
                                            0,
                                            transparent);
                                            outline: none;
                                        }
                                        &:focus {
                                            outline: none;
                                            @include shadow(0,
                                            0,
                                            transparent);
                                        }
                                    }
                                    ol {
                                        max-height: 200px;
                                        overflow: auto;
                                        padding-left: 15px;
                                        li {
                                            list-style-type: decimal;
                                            font-size: 16px;
                                            color: black;
                                            overflow: auto;
                                            max-height: 300px;
                                            list-style-position: inside;
                                        }
                                    }
                                }
                            }
                            .profile-image picture {
                                margin-top: 15px;
                                display: block;
                                border-radius: 50%;
                                width: auto;
                                height: auto;
                                max-height: 125px;
                                max-width: 125px;
                                //overflow: hidden;
                            }
                        }
                    }
                }
            }
            ul li {
                list-style: none;
            }
        }
        .main_modal.modal {
            .modal-header {
                color: #fff;
                //  border-bottom-color: lighten($citrex-primary,15%);
                border-bottom: none;
                background-color: rgba(white, 0.3);
                h4 {
                    font-weight: 300;
                    text-transform: uppercase;
                }
                button.close {
                    color: #fff !important;
                    text-shadow: none;
                    opacity: 1;
                }
            }
            .modal-content {
                //  background-color: lighten($brand-success,00%);
                background-color: lighten($citrex-primary, 05%);
            }
            .modal-footer {
                border-color: lighten($citrex-primary, 15%);
            }
            .modal-dialog {
                width: 90%;
            }
            input.form-control.video-name {
                background-color: transparent;
                font-size: 24px;
                color: #fff;
                box-shadow: none;
                outline: none;
                padding: 0;
                margin-bottom: 0;
                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #fff;
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #fff;
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #fff;
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #fff;
                }
            }
            textarea.form-control {
                background-color: rgba(white, 0.5);
                border-radius: 6px;
                border: none;
                resize: none;
                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #fff;
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #fff;
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #fff;
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #fff;
                }
            }
            ul.nav-tabs {
                margin-top: 15px !important;
                li {
                    a {
                        background-color: #C9D3DF;
                        color: #8393A7;
                        font-weight: 300;
                        margin-right: 0;
                        transition: all 0.4s;
                    }
                    &.active a {
                        background-color: $brand-warning;
                        color: #fff;
                        border: 1px solid $brand-warning;
                        border-bottom-color: transparent;
                    }
                }
            }
            .tab-content {
                background-color: $brand-warning;
                padding: 15px;
                border-radius: 0 0 6px 6px;
                color: #fff;
                .input-group {
                    .input-group-btn {
                        vertical-align: top;
                    }
                    button {
                        padding: 10px;
                        border: none;
                        background-color: $brand-success;
                        color: #fff;
                        fill: #fff;
                    }
                }
            }
            .my_drop_zone {
                border: 2px dashed $citrex-gray;
                min-height: 100px;
                margin-top: 15px;
                text-align: center;
                padding: 35px;
            }
            .file_drop_zone {
                border: 2px dashed $citrex-gray;
                min-height: 100px;
                margin-top: 15px;
                text-align: center;
                padding: 35px;
            }
        }
    }
    /* ==========================================================================
   Lessons
   ========================================================================== */

    body.citrex.lessons {
        ul {
            margin-left: 0;
            padding-left: 0;
            li {
                list-style: none;
            }
        }

        .main_modal.modal {
            .modal-header {
                color: #fff;
                //  border-bottom-color: lighten($citrex-primary,15%);
                border-bottom: none;
                background-color: rgba(white, 0.3);
                h4 {
                    font-weight: 300;
                    text-transform: uppercase;
                }
                button.close {
                    color: #fff !important;
                    text-shadow: none;
                    opacity: 1;
                }
            }
            .modal-content {
                //  background-color: lighten($brand-success,00%);
                background-color: lighten($citrex-primary, 05%);
            }
            .modal-footer {
                border-color: lighten($citrex-primary, 15%);
            }
            .modal-dialog {
                width: 90%;
            }
            input.form-control.video-name {
                background-color: transparent;
                font-size: 24px;
                color: #fff;
                box-shadow: none;
                outline: none;
                padding: 0;
                margin-bottom: 0;
                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #fff;
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #fff;
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #fff;
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #fff;
                }
            }
            textarea.form-control {
                background-color: rgba(white, 0.5);
                border-radius: 6px;
                border: none;
                resize: none;
                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #fff;
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #fff;
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #fff;
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #fff;
                }
            }
            ul.nav-tabs {
                margin-top: 15px !important;
                li {
                    a {
                        background-color: #C9D3DF;
                        color: #8393A7;
                        font-weight: 300;
                        margin-right: 0;
                        transition: all 0.4s;
                    }
                    &.active a {
                        background-color: $brand-warning;
                        color: #fff;
                        border: 1px solid $brand-warning;
                        border-bottom-color: transparent;
                    }
                }
            }
            .tab-content {
                background-color: $brand-warning;
                padding: 15px;
                border-radius: 0 0 6px 6px;
                color: #fff;
                .input-group {
                    .input-group-btn {
                        vertical-align: top;
                    }
                    button {
                        padding: 10px;
                        border: none;
                        background-color: $brand-success;
                        color: #fff;
                        fill: #fff;
                    }
                }
            }
            .col-sm-3 .my_drop_zone {
                border: 2px dashed $citrex-gray;
                min-height: 100px;
                margin-top: 15px;
                text-align: center;
                padding: 35px;
            }
        }
    }
    .descriptionClass,#interactiveLessonContainer{
        color:white;
    }
    @media (max-width: $screen-xs-max){
        .iframe-button{
            z-index: 1001;
        }
        .descriptionClass,#interactiveLessonContainer{
            width: 100%;
            height: 100%;
            display: block;
            background-color: #284156;
            color:white;
        }

    }
    /* ==========================================================================
   Performance
   ========================================================================== */

    body.citrex.performance {
        header.subheader {
            padding: 0;
        }
        .legends-header {
            height: 34px;
            background-color: #33a3ff;
            color: #fff;
            font-size: 16px;
            font-weight: 300;
            margin-left: -15px;
            margin-right: -15px;
            p {
                margin: 0;
                padding: 6px;
            }
        }
        .table thead {
            tr th {
                padding: 6px;
                height: 30px;
                border-bottom: none;
                font-weight: 300;
            }
            .table-center {
                text-align: center;
            }

        }
        .table tbody tr td {
            border-bottom: 1px solid darken($citrex-primary, 3%);
            border-top: none;
            padding: 7px 0;
            color: $citrex-white;
        }
        .table tbody tr td{
            padding: 6px;
        }
        .table.grid {
            thead tr th {
                padding: 9px;
                border-bottom: 1px solid darken($citrex-white, 30%);
            }
            tbody tr {
                td {
                    background-color: transparent;
                    border-bottom: 1px solid darken($citrex-white, 30%);
                }
                td.success {
                    color: $brand-success;
                }
                td.warning {
                    color: $brand-warning;
                }
                td.danger {
                    color: $brand-danger;
                }
            }
        }
    }
    /* ==========================================================================
   Management
   ========================================================================== */

    body.management {
        header.subheader {
            padding: 0;
        }
        button.btn.clip {
            background-color: #C9D3DF;
            border-radius: 6px;
            padding: 2px;
            svg {
                margin-left: 0;
            }
        }
        .legends-header {
            height: 40px;
            background-color: #33a3ff;
            color: #fff;
            font-weight: 300;
            margin-right: -15px;
            p {
                margin: 0;
                padding: 6px;
            }
        }
        .table thead.legends-header th {
            height: 40px;
        }
        .context {
            ul.nav {
                margin-right: 15px;
                li a {
                    padding: 5px 15px;
                    background: none;
                    color: $citrex-white;
                    transition: all 0.4s;
                    cursor: pointer;
                    &:hover {
                        color: black;
                    }
                }
            }
        }
        .table thead {
            tr th {
                padding: 6px;
                height: 30px;
                border-bottom: none;
                font-weight: 300;
            }
            .table-center {
                text-align: center;
            }
        }
        .table tbody tr td {
            border-bottom: 1px solid darken($citrex-primary, 3%);
            border-top: none;
            padding: 7px;
            color: $citrex-white;
        }
        .table.grid {
            thead tr th {
                padding: 9px;
                border-bottom: 1px solid darken($citrex-white, 30%);
            }
            tbody tr {
                td {
                    background-color: transparent;
                    border-bottom: 1px solid darken($citrex-white, 30%);
                }
                td.success {
                    color: $brand-success;
                }
                td.warning {
                    color: $brand-warning;
                }
                td.danger {
                    color: $brand-danger;
                }
            }
        }
    }

    body.citrex.management.vimeo {
        .table a {
            color: $brand-warning;
        }
    }

    body.citrex.interactive .page {
        overflow: auto;
    }
    /* ==========================================================================
   Summery
   ========================================================================== */

    body.citrex.summery {
        h4.summery-title {
            // color: $brand-warning;
            color: yellow;
            font-weight: 100;
            font-size: 36px;
        }
        p.summery-type {
            color: $citrex-gray;
            font-weight: bold;
        }
        hr {
            border-color: $citrex-gray;
        }
        .summery-labels {
            color: #fff;
            font-weight: bold;
        }
        .summery-values {
            color: $citrex-gray
        }
    }

    body.citrex.videosort {
        ul li {
            list-style: none;
        }
        li.lesson {
            padding-top: 5px;
        }
        ul {
            margin: 0;
            padding: 0;
        }
        .form-group {
            position: relative;
            margin-bottom: 20px;
            input {
                margin-bottom: 0;
                // text-align: center;
            }
        }
        h4 {
            margin-top: 0;
        }
        .form-group.search {
            padding-bottom: 15px;
        }
        input[type=text]{
            margin-bottom: 0;
        }
        .profile-image picture{
/*            margin-top: 0 !important;
            width: 100px !important;
            height: 98px !important;*/
        }
    }
